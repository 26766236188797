$couleur_principale : #fff;
$couleur_secondaire: #1da1f2;

/* Général */
$body_background_color : #fff;
$content_background_color:#fff;
$texte_lien:rgb(113, 113, 113);
$texte_lien_hover:$couleur_secondaire;
$header_background:$couleur_principale;
$slogan_color_texte:$couleur_secondaire;
$fond_titre_color:$couleur_secondaire;
$titre_color:#fff;
$texte-couleur:#2e2e2e;

$blocFil:$couleur_secondaire;
$blocFilText:#fff;

/* Boutons */

$button_background:$couleur_secondaire;
$button_border:$couleur_secondaire;
$button_color:#fff;
$button_background_hover:#fff;
$button_color_hover:$couleur_secondaire;
$button_border_hover:$couleur_secondaire;

/* Menu */
$nav_background_color:transparent;
$nav_texte_color:rgb(113, 113, 113);
$nav_texte_color_hover:$couleur_secondaire;
$li_background_color:#fff;
$li_background_color_hover:#fff;
$sous_nav_texte_color:rgb(113, 113, 113);
$sous_nav_texte_color_hover:$couleur_secondaire;
$sous_li_background_color:#fff;
$sous_li_background_color_hover:#fff;
$nav_border_color:#D0D0D0;
/* Menu responsive */
$burger_background_color:$couleur_principale;
$burger_texte_color:#000;
$dropdown_color:$couleur_principale;
$dropdown_color_hover:$couleur_principale;

/* Produits */
$mask_background:$couleur_principale;
$mask_lien_color:#000;
$mask_lien_color_hover:$couleur_secondaire;
$etiquette_produit_background:$couleur_secondaire;
$etiquette_produit_texte:#fff;
$remise_background_color:$couleur_secondaire;
$remise_texte_color:#fff;
$border-addbasket-zoom:rgba(0,0,0,.2);
$produit_background:transparent;
/* Fiche produit */
$fiche_prod_border_color:$couleur_secondaire;

/* Galerie photo*/
$galerie_texte_color:#fff;

/* Livre d'or */
$message_lo_color:$couleur_secondaire;

/* Blog / Boutons */
$btn_background_color:#fff;
$btn_texte_color:$couleur_secondaire;
$btn_border_color:$couleur_secondaire;
$btn_background_color_hover:$couleur_secondaire;
$btn_texte_color_hover:#fff;
$btn_border_color_hover:$couleur_secondaire;

/* Slider */
$slider_rond_border-background:rgba(255,255,255,1);
$slider_rond_hover_border-background:rgba(255,255,255,.5);
$slider_texte_color:#000;
$slider_texte_color_seconde_ligne:rgb(117, 117, 117);

$diapo_rond_border:$couleur_secondaire;
$diapo_rond_hover_border:lighten($couleur_secondaire, 30%);

/* Footer */
$footer_background_color:$couleur_secondaire;
$footer_texte_color:#fff;

/* Template Panier */
$panier-background-color:#fff;
$panier-titre-color:$couleur_secondaire;
$panier-content-background-color:#fff;
$panier-texte-color:#000;

/* Flux panier */
$flux-panier_color-shadow:black;


@import "design/796series/declinaison";